<template>
  <a-board
    :over="over"
    :delayed="board.delayed"
    @pointerdown="transformStartHandler"
    @pointermove="transformMoveHandler"
    @pointerup="transformEndHandler"
    @pointerout="transformEndHandler"
  />
</template>

<script setup>
import container from '@di'
import { fabric } from 'fabric'
import { onMounted, onUnmounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useUserStore,
  useBoardStore,
  useSessionStore,
  useTransformStore,
  useSnapshotStore,
  useNotificationStore
} from '@/stores'
import { useAppMachine } from '@/state'
import { Dart, Tap } from '@/libraries/fabric'
import { board as messages, transform as transformMessages } from '@/messages'
import { capitalizeFirst, animateColor } from '@/utils'
import { useAwsService } from '@/composables'
import { useThrottleFn } from '@vueuse/core'
import { logger } from '@logger'
import { useTranslation } from 'i18next-vue'

defineProps({ over: Boolean })

const user = useUserStore()
const board = useBoardStore()
const session = useSessionStore()
const transform = useTransformStore()
const snapshot = useSnapshotStore()
const notification = useNotificationStore()
const boardProcedure = container.procedures.get('boardProcedure')
const snapshotProcedure = container.procedures.get('snapshotProcedure')
const transformProcedure = container.procedures.get('transformProcedure')
const { transform: transformRef } = storeToRefs(session)
const { callTextract } = useAwsService()
const { t } = useTranslation()

const { send, service } = useAppMachine()

const dart = new Dart()
const tap = new Tap()
let canvas = null

const state = {
  listening: false,
  panning: false,
  object: null,
  selection: null,
  pointer: {},
  selectedProps: null,
  backup: { props: {} },
  ocrFields: [],
  ocrText: ''
}

const pinch = {
  cache: [],
  start: 0,
  zoom: 1,
  value: 1,
  listening: false,
  pointer: null,
  hypot: function () {
    const [first, second] = this.cache
    return Math.hypot(first.clientX - second.clientX, first.clientY - second.clientY)  
  }
}

// SIGNAL HANDLERS

container.messenger.subscribe('boardComponent', 'board:signal', (type) => {
  switch (type) {
    case 'object:discard': {
      canvas.discardActiveObject().renderAll()
      break
    }
    case 'object:remove': {
      const { selection } = state
      if (selection) {
        canvas.remove(selection)
      }
      break
    }
    case 'ocr:recognize': {
      recognizeOcr()
      break
    }
    case 'ocr:discard': {
      discardOcr()
      break
    }
    case 'snapshot:save': {
      saveSnapshot()
      break
    }
    case 'ocr:copyAllOcr': {
      copyAllOcr()
      break
    }
  }
})


// STATE HANDLERS

service.subscribe((state, event) => {
  if (canvas) {
    const { type } = event
    let { meeting } = state.value.app.view
    if (typeof meeting === 'object') {
      meeting = Object.keys(meeting).map((key) => `${key}.${meeting[key]}`).join('')
    }
    switch (meeting) {
      case 'call': {
        if (type === 'LEAVE_DRAW') {
          discardOcr()
          canvas.clear()
          transform.setTransform({
            zoom: 1,
            x: 0,
            y: 0
          })
          if (board.task === 'darting') {
            canvas.add(dart.shape)
          } else {
            board.setTask(null)
            board.setShape('rect')
          }
          if (state.object) {
            if (state.object.isEditing) {
              state.object.exitEditing()
            }
            state.object = null
          }
          if (board.delayed) {
            board.setDelayed(false)
          }
        }
        break
      }
      case 'draw.default': {
        canvas.backgroundColor = 'black'
        break
      }
    }
  }
})

// STORE HANDLERS

board.$onAction(({ name, args }) => {
  switch (name) {
    case 'setTask': {
      const [task] = args
      fabric.Object.prototype.selectable = task === 'selecting'
      canvas.remove(dart.shape)
      canvas.isDrawingMode = false
      canvas.discardActiveObject().renderAll()
      cancelTextEditing()
      toggleOcrFields(task === 'selecting')
      switch (task) {
        case 'selecting': {
          canvas.defaultCursor = 'default'
          canvas.hoverCursor = 'move'
          canvas.moveCursor = 'move'
          break
        }
        case 'darting': {
          canvas.defaultCursor = 'none'
          canvas.hoverCursor = 'none'
          canvas.moveCursor = 'none'
          canvas.add(dart.shape)
          break
        }
        case 'panning': {
          canvas.defaultCursor = 'all-scroll'
          canvas.hoverCursor = 'all-scroll'
          canvas.moveCursor = 'all-scroll'
          break
        }
        default: {
          canvas.defaultCursor = 'default'
          canvas.hoverCursor = 'default'
          canvas.moveCursor = 'default'
        }
      }
      break
    }
    case 'setShape': {
      // TODO: typing as task?
      cancelTextEditing()
      if (!board.task) {
        const [shape] = args
        canvas.isDrawingMode = shape === 'freedraw'
      }
      break
    }
    case 'setSize': {
      const [size] = args
      if (state.selection) {
        state.selection.modifyObject({ size })
        canvas.renderAll()
      } else {
        canvas.freeDrawingBrush.width = size
      }
      break
    }
    case 'setColor': {
      const [color] = args
      if (state.selection) {
        state.selection.modifyObject({ color })
        canvas.renderAll()
      } else {
        canvas.freeDrawingBrush.color = color
      }
      break
    }
  }
})

user.$onAction(({ name }) => {
  if (name === 'changeRole') {
    board.setTask(null)
  }
})

transform.$onAction(({ name, after }) => {
  switch (name) {
    case 'setTransform': {
      after(() => {
        updateViewportTransform()
      })
      break
    }
    case 'setTranslation': {
      after(() => {
        if (user.isObserver) {
          updateViewportTransform()
        }
      })
      break
    }
  }
})

// CANVAS HANDLERS

function setCanvasEventHandlers() {
  canvas
  .on('mouse:down', (event) => {
    const { target } = event
    state.listening = true
    canvas.discardActiveObject().renderAll()
    if (target instanceof fabric.Object) {
      target.enable(board.task === 'selecting' && !target.passive)
    }
    if (state.panning || board.task === 'panning') {
      state.pointer = getPointer(event.e, false)
      return
    }
    if (board.task) {
      if (board.task === 'selecting') {
        if (state.ocrFields.includes(target)) {
          // copyOcrField(target, event.e.ctrlKey)
          const colorDefault = 'rgb(0,255,255)'
          const colorSelected = 'rgb(74,251,0)'
          if(target.selected){
            target.selected = false
            target.stroke = colorDefault
          }else{
            target.selected = true
            target.stroke = colorSelected
          }
          copySelectedOcr(target, event.e.ctrlKey)
          canvas.renderAll()
        } else {
          canvas.setActiveObject(target)
        }
        state.listening = false
        return
      }
      state.pointer = getPointer(event.e)
      switch (board.task) {
        case 'typing': {
          if (state.object?.isEditing) {
            state.object.exitEditing()
            state.object = null
          } else {
            state.object = new fabric.IText('')
            state.object.initObject(state.pointer, board.color, 1) // TODO: remove from IText?
            canvas.add(state.object)
            state.object.enterEditing()
          }
          break
        }
        case 'shaping': {
          state.object = new fabric[capitalizeFirst(board.shape)]()
          state.object.initObject(state.pointer, board.color, board.size)
          canvas.add(state.object)
          break
        }
        case 'darting': {
          dart.cast(board.color)
          canvas.renderAll()
          canvas.fire('dart:casted', { data: dart.getDescription('start') })
          break
        }
      }
    }
  })
  .on('mouse:move', (event) => {
    if (board.task === 'darting') {
      dart.move(getPointer(event.e))
      canvas.renderAll()
    }
    if (state.listening) {
      if (state.panning || board.task === 'panning') {
        const pointer = getPointer(event.e, false)
        const delta = new fabric.Point(pointer.x - state.pointer.x, pointer.y - state.pointer.y)
        const { x, y } = transform
        const { scale } = session.transform
        transform.silentSetTranslation({
          x: x + (delta.x / scale),
          y: y + (delta.y / scale),
        })
        canvas.relativePan(delta)
        state.pointer = { ...pointer }
        return
      }
      if (board.task) {
        const pointer = getPointer(event.e)
        switch (board.task) {
          case 'shaping': {
              state.object.drawShape(pointer)
              canvas.renderAll()
              break
            }
          case 'darting': {
            canvas.fire('dart:casted', { data: dart.getDescription('move') })
            break
          }
        }
      }
    }
  })
  .on('mouse:up', () => {
    if (state.listening) {
      state.listening = false
      if (state.panning || board.task === 'panning') {
        const { x, y } = transform
        canvas.fire('canvas:moved', {
          data: { x, y }
        })
        return
      }
      switch (board.task) {
        case 'shaping': {
          canvas.fire('shape:created', {
            target: state.object
          })
          state.object.setCoords()
          state.object = null
          break
        }
        case 'darting': {
          dart.uncast()
          canvas.renderAll()
          canvas.fire('dart:casted', { data: dart.getDescription('stop') })
          break
        }
      }
    }
  })
  .on('shape:created', (event) => {
    const description = event.target.getDescription('create')
    boardProcedure.send(messages.shapeCreatedMessage(description))
  })
  .on('path:created', (event) => {
    const { path } = event
    path.initObject()
    const description = path.getDescription('create')
    boardProcedure.send(messages.shapeCreatedMessage(description))
  })
  .on('text:editing:exited', (event) => {
    const { target } = event
    if (target.text.replace(/\s/g, '').length) {
      const description = target.getDescription('create')
      boardProcedure.send(messages.shapeCreatedMessage(description))
    } else {
      setTimeout(() => {
        canvas.remove(target)
      })
    }
  })
  .on('object:modified', (event) => {
    const { target } = event
    const { selectedProps } = state
    if (selectedProps) {
      const description = target.getDescription('modify')
      const { id } = description
      const { oldProps, newProps } = diffProps(selectedProps, description)
      target.setCoords()
      console.log('history:modify', id, oldProps, newProps)
      state.selectedProps = description
      boardProcedure.send(messages.shapeModifiedMessage({ id, ...newProps }))
    }
  })
  .on('selection:created', (event) => {
    const [target] = event.selected
    target.setControlsVisibility({ mtr: false })
    state.selection = target
    state.selectedProps = target.getDescription('modify')
    state.backup.props = {
      color: board.color,
      size: board.size
    }
    board.silentSetColor(state.selectedProps[target.translateProps.color])
    board.silentSetSize(state.selectedProps[target.translateProps.size])
    send('DRAW_SELECT')
  })
  .on('selection:cleared', (event) => {
    const { color, size } = state.backup.props
    state.selection = null
    state.selectedProps = null
    state.backup.props = {}
    board.silentSetColor(color)
    board.silentSetSize(size)
    send('DRAW_DEFAULT')
  })
  .on('object:removed', (event) => {
    if (service.state.matches('app.view.meeting.draw')) {
      const { target } = event
      const description = target.getDescription()
      boardProcedure.send(messages.shapeRemovedMessage(description))
    }
  })
  .on('dart:casted', (event) => {
    boardProcedure.send(messages.dartCastedMessage(event.data))
  })
  .on('canvas:moved', (event) => {
    transform.setTranslation(event.data)
    transformProcedure.send(transformMessages.translateMessage(event.data))
  })
}

// DOCUMENT HANDLERS

const documentKeydownHandler = (event) => {
  if (user.isAssistant) {
    const { key, ctrlKey } = event
    switch (key) {
      case 'Delete': {
        if (state.selection && !state.selection.isEditing) {
          event.preventDefault()
          canvas.remove(state.selection)
        }
        break
      }
      case 'Alt': {
        if (!state.panning && !ctrlKey) {
          event.preventDefault()
          canvasBackup({
            defaultCursor: 'all-scroll',
            hoverCursor: 'all-scroll',
            moveCursor: 'all-scroll',
            isDrawingMode: false
          })
          if (state.selection) {
            state.backup.selection = state.selection
          }
          state.panning = true
        }
      }
    }
  }
}

const documentKeyupHandler = (event) => {
  if (user.isAssistant) {
    const { key } = event
    if (key === 'Alt' && state.panning) {
      if (state.listening) {
        canvas.fire('mouse:up')
      }
      canvasRestore()
      if (state.backup.selection) {
        canvas.setActiveObject(state.backup.selection).renderAll()
        delete state.backup.selection
      }
      state.panning = false
    }
  }
}

function setDocumentEventHandlers () {
  document.addEventListener('keydown', documentKeydownHandler)
  document.addEventListener('keyup', documentKeyupHandler)
}

function removeDocumentEventHandlers () {
  document.removeEventListener('keydown', documentKeydownHandler)
  document.removeEventListener('keyup', documentKeyupHandler)
}

// ELEMENT HANDLERS

function transformStartHandler (event) {
  if (user.isAssistant && (board.task === 'panning' || (!board.task && board.shape !== 'freedraw'))) {
    pinch.cache.push(event)
    pinch.listening = true
    if (!pinch.pointer) {
      pinch.pointer = getPointer(event, false)
    }
    if (pinch.cache.length === 2) {
      const { zoom } = transform
      if (board.task === 'panning') {
        const { x: x1, y: y1 } = state.pointer
        const { x: x2, y: y2 } = pinch.pointer
        if (x1 !== x2 || y1 !== y2) {
          canvas.fire('mouse:up')
        }
      }
      state.listening = false
      pinch.start = pinch.hypot()
      pinch.zoom = zoom
    }
  }
}

const transformMoveHandler = useThrottleFn((event) => {
  if (pinch.listening) {
    const index = pinch.cache.findIndex((item) => item.pointerId === event.pointerId)
    pinch.cache[index] = event
    if (pinch.cache.length === 2) {
      const { user: { width, height } } = session.transform
      const delta = (width + height) / 4
      const distance = pinch.hypot() - pinch.start
      pinch.value = pinch.zoom + (distance / delta * pinch.zoom)
      transform.updateZoom(pinch.value, true)
    }
  }
}, 100)

function transformEndHandler (event) {
  if (pinch.listening) {
    const index = pinch.cache.findIndex((index) => index.pointerId === event.pointerId)
    if (pinch.cache.length === 2) {
      transform.updateZoom(pinch.value)
    }
    pinch.cache.splice(index, 1)
    if (pinch.cache.length === 0) {
      pinch.listening = false
      pinch.pointer = null
    }
  }
}

// PROCEDURE LISTENERS

const procedureListener = (message) => {
    // TODO: refaktor procedure listener
  const { type, data } = message
  switch (type) {
    case 'board:shape:created': {
      const { type } = data
      let object = {}
      if (type === 'path') {
        fabric.Path.fromObject(data, (path) => {
          object = path
        })
      } else {
        object = new fabric[capitalizeFirst(type)](type === 'i-text' ? '' : null)
        object.set(data)
      }
      canvas.add(object).renderAll()
      break
    }
    case 'board:shape:modified': {
      const { id } = data
      const object = canvas.getObjectById(id)
      object.set(data).setCoords()
      canvas.renderAll()
      break
    }
    case 'board:shape:removed': {
      const { id } = data
      const object = canvas.getObjectById(id)
      canvas.remove(object).renderAll()
      break
    }
    case 'board:dart:casted': {
      const { action } = data
      if (action === 'stop') {
        canvas.remove(dart.shape)
      } else {
        dart.shape.set(data)
        if (action === 'start') {
           canvas.add(dart.shape)
        }
      }
      canvas.renderAll()
      break
    }
    case 'board:tap:casted': {
      const { action } = data
      if (action === 'stop') {
        canvas.remove(tap.shape)
      } else {
        tap.shape.set(data)
        if (action === 'start') {
          canvas.add(tap.shape)
        }
      }
      canvas.renderAll()
      break
    }
  }
}

async function snapshotListener (data) {
  await addSnapshot(data)
  if (board.task === 'darting') {
    canvas.bringToFront(dart.shape)
  }
}

boardProcedure.addListener(procedureListener)
snapshotProcedure.addListener(snapshotListener)

// METHODS

function addSnapshot (data) {
  return new Promise((resolve) => {
    new fabric.Image().setSrc(data, (object) => {
      object.initSnapshot(session.transform.client)
      const { scaleX: scale, left, top, width, height } = object
      snapshot.setTransform({ scale, left, top, width, height })
      canvas.add(object).renderAll()
      resolve()
    })
  })
}

function cancelTextEditing () {
  if (state.object?.isEditing) {
    state.object.exitEditing()
  }
}

function diffProps (original, modified) {
  const oldProps = {}
  const newProps = {}
  Object.keys(modified).forEach((key) => {
    if (modified[key] !== original[key]) {
      oldProps[key] = original[key]
      newProps[key] = modified[key]
    }
  })
  return { oldProps, newProps }
}

function getPointer (event, withTransform = true) {
  return withTransform ? canvas.getPointer(event) : {
    x: event.clientX || event.changedTouches[0].clientX,
    y: event.clientY || event.changedTouches[0].clientY
  }
}

function updateViewportTransform (newValue, oldValue) {
  const { scale, left, top, user: { width, height } } = session.transform
  const { zoom, x, y } = transform
  canvas.setDimensions({
    width,
    height
  })
  canvas.viewportTransform = [
    scale * zoom,
    0,
    0,
    scale * zoom,
    left + (x * scale),
    top + (y * scale)
  ]
  dart.updateScale(scale * zoom)
  tap.updateScale(scale * zoom)
  canvas.setAllCoords()
  canvas.renderAll()

  if (user.isAssistant && newValue?.client?.width && service.state.matches('app.view.meeting.draw')) {
    const { width: nWidth, height: nHeight } = newValue.client
    const { width: oWidth, height: oHeight } = oldValue.client
    const { x, y } = transform
    const translation = {
      x: x + ((nWidth - oWidth) / 2),
      y: y + ((nHeight - oHeight) / 2)
    }
    transform.setTranslation(translation)
    transformProcedure.send(transformMessages.translateMessage(translation))
    updateViewportTransform()
  }
}

function canvasBackup (obj) {
  state.backup.canvas = {}
  Object.keys(obj).forEach((key) => {
    state.backup.canvas[key] = canvas[key]
    canvas[key] = obj[key]
  })
}

function canvasRestore () {
  Object.keys(state.backup.canvas).forEach((key) => {
    canvas[key] = state.backup.canvas[key]
  })
}

async function recognizeOcr () {
  try {
    notification.showMessage({
      text: t('notification.ocrAnalysing'),
      icon: 'font'
      
    })
    const response = await callTextract(snapshot.key)
    response.forEach((item) => {
      const { text } = item
      const selected = false
      const { left, top, width, height } = item.geometry.boundingBox
      const { scale, left: snapshotLeft, top: snapshotTop, width: snapshotWidth, height: snapshotHeight } = snapshot.transform
      const rect = new fabric.Rect()
      rect.initOcrField({
        left: (left * snapshotWidth * scale) + snapshotLeft,
        top: (top * snapshotHeight * scale) + snapshotTop,
        width: width * snapshotWidth * scale,
        height: height * snapshotHeight * scale,
        text,
        selected
      })
      state.ocrFields.push(rect)
      canvas.add(rect)
    })
    board.setOcr(true)
    board.setTask('selecting')
    notification.showMessage({
      text: t('notification.ocrFound', { lines: response.length }),
      icon: 'font',
      auto: true
    })
    logger('board', 'info', 'ocr fields', { response })
  } catch (error) {
    notification.showMessage({
      text: t('notification.ocrNotFound'),
      icon: 'font-off',
      auto: true
    })
    console.error(error)
  }
}

function discardOcr () {
  state.ocrFields.forEach((item) => {
    canvas.remove(item)
  })
  state.ocrFields = []
  state.ocrText = ''
  board.setOcr(false)
}

function copySelectedOcr(field){
  let result = state.ocrFields.reduce((acc, item) => 
  item.selected ? acc + (acc ? ', ' : '') + item.text : acc, '');
  navigator.clipboard.writeText(result)
    .then(() => {
      //'notification.clipboardCopy'
      notification.showMessage({
        text: t('notifications.clipboardCopy'),
        icon: 'save',
        auto: true
      })
    })
    .catch((error) => {
      // 'notification.clipboardCopyError'
      notification.showMessage({
        text: t('notifications.clipboardCopyError'),
        icon: 'save',
        auto: true
      })
    })
    const animateHandler = (event) => {
    const { color: fill } = event
    field.set({ fill })
    canvas.renderAll()
  }
  animateColor({
    from: 'rgba(255, 255, 255, 0.6)',
    to: 'rgba(0, 255, 255, 0.4)',
    duration: 150,
    onChange: animateHandler,
    onComplete: animateHandler
  })
}

function copyAllOcr(){
  let result = state.ocrFields.reduce((acc, cur) => acc + (acc ? ', ' : '') + cur.text, '');
  navigator.clipboard.writeText(result)
    .then(() => {
      //'notification.clipboardCopy'
      notification.showMessage({
        text: t('notifications.clipboardCopy'),
        icon: 'save',
        auto: true
      })
    })
    .catch((error) => {
      // 'notification.clipboardCopyError'
      notification.showMessage({
        text: t('notifications.clipboardCopyError'),
        icon: 'save',
        auto: true
      })
    })
}

function copyOcrField (field, add = false) {
  let { text } = field
  if (add) {
    const { ocrText } = state
    text = `${ocrText} ${text}`
  }
  navigator.clipboard.writeText(text)
    .then(() => {
      state.ocrText = text
      //'notification.clipboardCopy'
    })
    .catch((error) => {
      // 'notification.clipboardCopyError'
      console.error(`copyOcrField: ${error}`)
    })
  const animateHandler = (event) => {
    const { color: fill } = event
    field.set({ fill })
    canvas.renderAll()
  }
  animateColor({
    from: 'rgba(255, 255, 255, 0.6)',
    to: 'rgba(0, 255, 255, 0.4)',
    duration: 150,
    onChange: animateHandler,
    onComplete: animateHandler
  })
}

function toggleOcrFields (show) {
  const { ocrFields } = state
  if (ocrFields.length) {
    const colors = ['rgba(0, 255, 255, 0.4)', 'rgba(0, 255, 255, 0)']
    if (show) {
      colors.reverse()
    }
    const [from, to] = colors
    const animateHandler = (event) => {
      const { color: fill, type } = event
      ocrFields.forEach((item) => {
        item.set({ fill })
        if (type === 'complete') {
          const hoverCursor = show ? 'copy' : null
          item.set({ hoverCursor })
        }
      })
      canvas.renderAll()
    }
    animateColor({
      from,
      to,
      duration: 150,
      onChange: animateHandler,
      onComplete: animateHandler
    })
  }
}

function saveSnapshot () {
  const { width: snapshotWidth, height: snapshotHeight } = snapshot.transform
  const { top, left, scale, client: { width: clientWidth, height: clientHeight} } = session.transform
  const { width: userWidth, height: userHeight } = user.profile.viewport
  const width = clientWidth * scale
  const height = clientHeight * scale
  const multiplier = 1 / Math.min(userWidth / snapshotWidth, userHeight / snapshotHeight, 1)
  const data = canvas.toDataURL({
    format: 'jpeg',
    quality: 0.9,
    left,
    top,
    width,
    height,
    multiplier
  })
  snapshotProcedure.save(data)
}

// WATCHERS

watch(transformRef, updateViewportTransform)

// LIFECYCLE HOOKS

onMounted(() => {
  canvas = new fabric.Canvas('board')
  updateViewportTransform()
  setCanvasEventHandlers()
  setDocumentEventHandlers()
  canvas.freeDrawingBrush.color = board.color
  canvas.freeDrawingBrush.width = board.size
  // window.__canvas = canvas
})

onUnmounted(() => {
  container.messenger.unsubscribe('boardComponent')
  removeDocumentEventHandlers()
})


</script>
