<template>
  <a-modal :title="`Access to your ${context} is blocked`" @close="app.transition('HIDE_PERMISSIONS_MODAL')">
    <p>#todo To allow access to your {{context}} click on the lock icon and turn on {{context}}.</p>
    <img class="w--100 tutorial" :src='`/tutorials/${imgTutorial}.gif`' alt="" />   
    <template #footer>
      <a-button
        @click="app.transition('HIDE_PERMISSIONS_MODAL')"
      >
        OK
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores'
import { useAppMachine } from '@/state'

const app = useAppStore()
const { state } = useAppMachine()
const context = computed(() => state.value.context.permissions)

const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
const platform = navigator.userAgent.toLowerCase();
let deviceType = 'PC';
let browser = 'Chrome';
let imgTutorial = context;

if(/android/i.test(platform)){
  deviceType = 'Android';
}else if(/iphone|ipad|ipod/i.test(platform)){
  deviceType = 'iOS';
}

if(platform.includes('edg')){
  browser = 'Edge';
}else if(platform.includes('firefox')){
  browser = 'Firefox';
}else if(platform.includes('crios') || platform.includes('chrome')){
  browser = 'Chrome';
}else if(platform.includes('safari') && !platform.includes('crios') && !platform.includes('fxios')){
  browser = 'Safari';
}

if (isMobile) {
  if(deviceType === 'iOS'){
    imgTutorial = (browser === 'Chrome') ? 'chrome-ios' : 'safari';
  }else if(deviceType === 'Android'){
    if(browser === 'Chrome'){
      imgTutorial = 'chrome-android';
    }else if(browser === 'Firefox'){
      imgTutorial = 'firefox';
    }else{
      imgTutorial = 'edge';
    }
  }
}

</script>
<style lang="scss">
  .tutorial{
    height: 500px;
    object-fit: contain;
  }
</style>
